import { Form } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  useAddBudgetPnlMutation,
  useEditBudgetPnlMutation,
} from "src/app/services/finance";
import {
  IBudgetPnlForm,
  IBudgetPnlRes,
  PnlTypeAttributes,
} from "src/app/services/finance/type";
import { useTypedSelector } from "src/app/store";
import { monthFormat } from "src/components/filter/FilterDate";

export interface IAddProps {
  handleGet: () => void;
  editData?: IBudgetPnlRes;
  setEditData?: React.Dispatch<React.SetStateAction<IBudgetPnlRes | undefined>>;
  hasMain?: boolean;
}

export default function useAddBudgetPnl({
  handleGet,
  editData,
  setEditData,
}: IAddProps) {
  const [form] = Form.useForm<IBudgetPnlForm>();

  //Modal visible
  const [visible, setVisible] = useState(false);
  const [start_date, setStartDate] = useState<null | string>(null);
  const showModal = (type: PnlTypeAttributes) => {
    setVisible(true);
    setType(type);
  };
  const closeModal = () => {
    setVisible(false);
    form.resetFields();
    setEditData?.(undefined);
  };

  //Fetch
  const [add, { isLoading: addLoading }] = useAddBudgetPnlMutation();
  const [edit, { isLoading: editLoading }] = useEditBudgetPnlMutation();
  const isLoading = addLoading || editLoading;

  const onFinish = (val: IBudgetPnlForm) => {
    let { date, amount, currency, ...rest } = val;
    if (!date) return;
    let body = {
      ...rest,
      date: date.format("YYYY-MM") + "-01",
      profit: type === "profit" ? amount : 0,
      loss: type === "loss" ? amount : 0,
    };
    if (editData) {
      edit({ id: editData.id, body })
        .unwrap()
        .then(() => onSuccess());
    } else {
      add(body)
        .unwrap()
        .then(() => onSuccess());
    }
  };

  const onSuccess = () => {
    closeModal();
    handleGet();
  };

  //Set default value
  const [type, setType] = useState<PnlTypeAttributes>();
  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        date: dayjs(editData.date, monthFormat),
        exchange_rate: editData.exchange_rate,
        amount: editData.profit || editData.loss,
        comment: editData.comment,
        account: editData.account?.id?.toString(),
        currency: editData.account.currency,
      });
      if (editData.profit) {
        form.setFieldsValue({
          direction: editData.direction?.id.toString(),
          cost: editData.cost,
        });
      } else {
        form.setFieldsValue({ source: editData.source?.id.toString() });
      }
      setCurrency(editData.account.currency);
      showModal(editData.profit ? "profit" : "loss");
    }
  }, [editData]);

  const { accounts } = useTypedSelector((state) => state.account);
  const [currency, setCurrency] = useState("");
  const onChangeAccount = (val: string) => {
    let curr = accounts?.find((el) => el.id === parseInt(val))?.currency;
    if (curr) {
      setCurrency(curr);
      form.setFieldsValue({ currency: curr });
    }
    setStartDate(
      accounts?.find((el) => String(el.id) === val)?.start_date || null,
    );
  };

  return {
    form,
    visible,
    isLoading,
    type,
    currency,
    start_date,
    onChangeAccount,
    showModal,
    closeModal,
    onFinish,
  };
}

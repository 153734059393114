import { ConfigProvider, DatePicker, Descriptions, Flex, Spin } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { useGetPCbetweenMutation } from "src/app/services/finance";
import locale from "antd/locale/en_GB";
import "dayjs/locale/en-gb";
dayjs.locale("en-gb");

export const PaymentCalendarCard = () => {
  const [date, setDate] = useState<null | string>(dayjs().format("YYYY-MM-DD"));
  const [from_date, setWeek] = useState<null | string>(null);

  const [getPCbetween, { data: paymentCalendarData, isLoading }] =
    useGetPCbetweenMutation();

  useEffect(() => {
    getPCbetween(`from_date=${from_date ?? ""}&date=${date ?? ""}`)
      .unwrap()
      .then((val) => console.log(val));
  }, [date, from_date]);

  return (
    <div className="dashboard-card payment-card" style={{ width: "100%" }}>
      <div className="dashboard-card-title">
        <p>To'lov kalendari</p>

        <Flex gap={8}>
          <ConfigProvider locale={locale}>
            <DatePicker
              value={from_date ? dayjs(from_date) : null}
              onChange={(val) => {
                setWeek(
                  moment(val?.toString())
                    .startOf("isoWeek")
                    .format("YYYY-MM-DD")
                );
                setDate(null);
              }}
              picker="week"
            />
          </ConfigProvider>
          <DatePicker
            value={date ? dayjs(date) : null}
            onChange={(val) => {
              setDate(moment(val.toString()).format("YYYY-MM-DD"));
              setWeek(null);
            }}
          />
        </Flex>
      </div>
      <div className="cash-card-body">
        <Spin spinning={isLoading} style={{ width: "100%" }}>
          <Descriptions
            size="small"
            bordered
            style={{ width: "100%" }}
            items={[
              {
                key: "1",
                label: "Sana",
                span: 3,
                children: moment(paymentCalendarData?.date).format(
                  "YYYY-MM-DD"
                ),
              },
              {
                key: "2",
                label: "Kirim",
                span: 3,
                children: paymentCalendarData?.income || 0,
              },
              {
                key: "3",
                label: "Chiqim",
                span: 3,
                children: paymentCalendarData?.outcome || 0,
              },
              {
                key: "4",
                label: "Qoldiq",
                children: paymentCalendarData?.remaining || 0,
              },
            ]}
          />
        </Spin>
      </div>
    </div>
  );
};

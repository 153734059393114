import { Form, Input, InputNumber, message } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useAddJournalMutation,
  useAddJournalPaymentCalendarMutation,
  useAddJournalPredictedMutation,
  useEditJournalMutation,
  useEditJournalPaymentCalendarMutation,
  useEditJournalPredictedMutation,
} from "src/app/services/finance";
import {
  IEditJournal,
  IFormJournal,
  IJournalResData,
} from "src/app/services/finance/type";
import { changeJournalAction } from "src/app/slices/journalSlice";
import { useAppDispatch, useTypedSelector } from "src/app/store";
import CustomModal from "src/components/common/modal";
import ModalFooter from "src/components/common/modal/ModalFooter";
import ModalHeader from "src/components/common/modal/ModalHeader";
import { dateFormat, monthFormat } from "src/components/filter/FilterDate";
import AccountFormItem from "src/components/formItem/AccountFormItem";
import ContingentFormItem from "src/components/formItem/ContingentFormItem";
import CurrencyFormItem from "src/components/formItem/CurrencyFormItem";
import DateFormItem from "src/components/formItem/DateFormItem";
import DirectionFormItem from "src/components/formItem/DirectionFormItem";
import SourceFormItem from "src/components/formItem/SourceFormItem";
import TeamFormItem from "src/components/formItem/TeamFormItem";

interface Props {
  isPredicted?: boolean;
  isPaymentCalendar?: boolean;
  editableData?: IJournalResData;
  setEditableData: React.Dispatch<
    React.SetStateAction<IJournalResData | undefined>
  >;
  handleGet: () => void;
}

function AddJounal({
  isPredicted,
  isPaymentCalendar,
  editableData,
  handleGet,
  setEditableData,
}: Props) {
  //Predicted section
  const { t } = useTranslation();
  const [
    addJournalPredicted,
    { isSuccess: addPredictedStatus, isLoading: addPredictedLoading },
  ] = useAddJournalPredictedMutation();
  const [
    editJournalPredicted,
    { isSuccess: editPredictedStatus, isLoading: editPredictedLoading },
  ] = useEditJournalPredictedMutation();
  const [editJournalPC, { isSuccess: editPCStatus, isLoading: editPCLoading }] =
    useEditJournalPaymentCalendarMutation();

  //Modal section
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditableData(undefined);
    form.resetFields();
    dispatch(changeJournalAction(undefined));
  };

  //Journal action
  const dispatch = useAppDispatch();
  const { journalAction } = useTypedSelector((state) => state.journal);
  useEffect(() => {
    if (journalAction) showModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journalAction]);

  //Form section
  const [form] = Form.useForm();
  const [addJournal, { isSuccess: addStatus, isLoading: addLoading }] =
    useAddJournalMutation();

  const [
    addPaymentCalendar,
    { isSuccess: addStatusPC, isLoading: addLoadingPC },
  ] = useAddJournalPaymentCalendarMutation();

  const [editJournal, { isSuccess: editStatus, isLoading: editLoading }] =
    useEditJournalMutation();
  const onFinish = (val: IFormJournal) => {
    let { date, is_expense, amount, exchange_rate, month, ...rest } = val;

    let obj: IEditJournal = {
      ...rest,
      income: journalAction === "income" ? amount : "0",
      outcome: journalAction === "outcome" ? amount : "0",
      exchange_rate: exchange_rate ? +exchange_rate : 0,
      date: Array.isArray(date)
        ? date.map((item) => {
            return item?.format("YYYY-MM-DD") || "";
          })
        : isPredicted
        ? date?.format("YYYY-MM") + "-01"
        : date?.format("YYYY-MM-DD"),
    };

    if (month) obj["month"] = month.format("YYYY-MM") + "-01";

    if (isPredicted) {
      if (editableData)
        editJournalPredicted({ ...obj, cashflow: editableData.id });
      else addJournalPredicted(obj);
    } else if (isPaymentCalendar) {
      if (editableData) editJournalPC({ ...obj, cashflow: editableData.id });
      else addPaymentCalendar(obj);
    } else {
      if (editableData) editJournal({ ...obj, cashflow: editableData.id });
      else addJournal(obj);
    }
  };

  useEffect(() => {
    if (addStatus || addPredictedStatus) {
      message.success(
        `${isPredicted ? "Predicted journal" : "Journal"} added ${
          isPredicted ? "" : "and send moderator to check"
        } successfully!`
      );
      handleGet();
      closeModal();
    }
    if (addStatusPC) {
      message.success(`"Payment calendar" added  successfully!`);
      handleGet();
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus, addPredictedStatus, addStatusPC]);

  useEffect(() => {
    if (editStatus || editPredictedStatus || editPCStatus) {
      message.success(
        `${
          isPredicted
            ? "Predicted journal"
            : isPaymentCalendar
            ? "Payment calendar"
            : "Journal"
        }  edited successfully!`
      );
      handleGet();
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editStatus, editPredictedStatus, editPCStatus]);

  //Set data for edit
  const handleSet = () => {
    if (editableData) {
      showModal();
      form.resetFields();
      let {
        income,
        outcome,
        user,
        date,
        currency,
        exchange_rate,
        account,
        source,
        comment,
        contingent,
        direction,
        month,
      } = editableData;

      const is_expense = outcome?.toString() === "0" ? false : true;
      form.setFieldsValue({
        is_expense,
        user: user?.id?.toString(),
        date: dayjs(date, isPredicted ? monthFormat : dateFormat),
        currency,
        amount: is_expense ? outcome : income,
        exchange_rate,
        account: account?.id.toString(),
        source: source?.id.toString(),
        direction: direction?.id.toString(),
        comment,
        contingent: contingent?.id.toString(),
      });
      if (month) form.setFieldValue("month", dayjs(month, monthFormat));
      setCurrency(account.currency);
      dispatch(changeJournalAction(is_expense ? "outcome" : "income"));
    }
  };

  useEffect(() => {
    handleSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableData]);

  const { accounts } = useTypedSelector((state) => state.account);
  const [accountId, setAccountId] = useState("");
  const [start_date, setStartDate] = useState<null | string>(null);

  const [currency, setCurrency] = useState("");
  useEffect(() => {
    let curr = accounts?.find((el) => String(el.id) === accountId)?.currency;
    if (curr) {
      setCurrency(curr);
      form.setFieldsValue({ currency: curr });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  return (
    <div className="add">
      <CustomModal
        padding={45}
        open={isModalOpen}
        width={640}
        onCancel={closeModal}
        centered={true}
      >
        <ModalHeader
          title={`${journalAction === "income" ? "Kirim" : "Chiqim"} ${
            isPredicted ? "rejasini" : ""
          } ${editableData ? "ma'lumotlarini tahrirlash" : "qo'shish"}`}
          // description="21.06.2023"
        />

        <Form
          form={form}
          requiredMark={false}
          name="normal_login"
          className="form-form"
          onFinish={onFinish}
          layout={"vertical"}
        >
          <AccountFormItem
            className={isPaymentCalendar && !editableData ? "full" : "half"}
            required
            onChange={(val) => {
              setAccountId(val);
              setStartDate(
                accounts?.find((el) => String(el.id) === val)?.start_date ||
                  null
              );
            }}
          />

          <DateFormItem
            disabledDate={(current: any) => current.isBefore(dayjs(start_date))}
            className={isPaymentCalendar && !editableData ? "full" : "half"}
            format={isPredicted ? "month" : "date"}
            multiple={isPaymentCalendar && !editableData}
          />

          <SourceFormItem className="half" />

          <ContingentFormItem className="half" />
          <DirectionFormItem className="half" />

          <CurrencyFormItem className="half" disabled />

          <Form.Item
            name="amount"
            label={`${t("common.amount")} *`}
            rules={[{ required: true, message: t("common.enterAmount") }]}
            className="half"
          >
            <InputNumber
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
              style={{ width: "100%" }}
            />
          </Form.Item>

          {currency && currency !== "uzs" && (
            <Form.Item
              name="exchange_rate"
              label={`${t("common.currencyRate")}*`}
              className="half"
              rules={[
                {
                  required: true,
                  message: t("common.enterCurrencyRate"),
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          )}

          {journalAction !== "income" ? (
            <>
              <TeamFormItem className="half" />
              <DateFormItem
                name="month"
                label={t("common.whichMonth")}
                className="half"
                format="month"
                required={false}
                tooltip={{ title: t("common.whichMonthInfo") }}
              />
            </>
          ) : (
            <div className="half" />
          )}

          <Form.Item
            name="comment"
            label={`${t("common.comment")} *`}
            rules={[{ required: true, message: t("common.enterComment") }]}
            className="full"
          >
            <Input.TextArea rows={4} />
          </Form.Item>

          <ModalFooter
            onCancel={closeModal}
            cancelText={t("common.check")}
            okLoading={
              addLoading ||
              addPredictedLoading ||
              editLoading ||
              editPredictedLoading ||
              editPCLoading ||
              addLoadingPC
            }
          />
        </Form>
      </CustomModal>
    </div>
  );
}

export default AddJounal;

import { DownloadOutlined } from "@ant-design/icons";
import { Table } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { hostName } from "src/app/services/api/const";
import {
  useGenerateCashflowMutation,
  useGetCashflowMutation,
} from "src/app/services/finance";
import { useTypedSelector } from "src/app/store";
import CustomButton from "src/components/common/button";
import FilterAccount from "src/components/filter/FilterAccount";
import FilterDate from "src/components/filter/FilterDate";
import ContentTop from "src/layout/content/ContentTop";
import { handleMakeUrl, redirectToNewTab } from "src/utils";
import "./cashFlow.scss";
import { cashFlowColumns } from "./constants";
import { ICashflowColumns } from "./type";
import { useTranslation } from "react-i18next";

function CashFlowPage() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { screenMode } = useTypedSelector((state) => state.layout);
  const [getCashflow, { isLoading }] = useGetCashflowMutation();

  useEffect(() => {
    let url = handleMakeUrl(searchParams);
    getCashflow(url);
  }, [searchParams]);

  const { cashflows } = useTypedSelector((state) => state.stat);

  const data: ICashflowColumns[] = [
    {
      key: "remains_first",
      sources: t("subCash.startMonthBalance"),
      ...cashflows?.beginnig_balance,
    },

    {
      key: "type1_title",
      sources: t("subCash.currentBalance"),
      ...cashflows?.type1_change,
    },
    {
      key: "type1_income",
      sources: t("common.income"),
      ...cashflows?.type1_income_total,
      children: cashflows?.type1_income,
    },
    {
      key: "type1_outcome",
      sources: t("common.outcome"),
      ...cashflows?.type1_outcome_total,
      children: cashflows?.type1_outcome,
    },
    {
      key: "type2_title",
      sources: t("common.financialMoneyFlow"),
      ...cashflows?.type2_change,
    },
    {
      key: "type2_income",
      sources: t("common.income"),
      ...cashflows?.type2_income_total,
      children: cashflows?.type2_income,
    },
    {
      key: "type2_outcome",
      sources: t("common.outcome"),
      ...cashflows?.type2_outcome_total,
      children: cashflows?.type2_outcome,
    },
    {
      key: "type3_title",
      sources: t("common.invistationMoneyFlow"),
      ...cashflows?.type3_change,
    },
    {
      key: "type3_income",
      sources: t("common.income"),
      ...cashflows?.type3_income_total,
      children: cashflows?.type3_income,
    },
    {
      key: "type3_outcome",
      sources: t("common.outcome"),
      ...cashflows?.type3_outcome_total,
      children: cashflows?.type3_outcome,
    },
    {
      key: "transfer",
      sources: t("subCash.changeAccount"),
    },
    {
      key: "transfer_income",
      sources: t("common.income"),
      ...cashflows?.transfer_income,
    },
    {
      key: "transfer_outcome",
      sources: t("common.outcome"),
      ...cashflows?.transfer_outcome,
    },
    {
      key: "total_change",
      sources: t("subCash.total_change"),
      ...cashflows?.total_change,
    },
    {
      key: "remains_last",
      sources: t("subCash.remains_last"),
      ...cashflows?.ending_balance,
    },
  ];

  //Generate section
  const [generateCashflow, { data: generatedUrl }] =
    useGenerateCashflowMutation();

  const generateExcel = () => {
    generateCashflow();
  };

  useEffect(() => {
    if (generatedUrl) redirectToNewTab(hostName + generatedUrl.link);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatedUrl]);

  //Table height
  const [tableHeight, setTableHeight] = useState<number>();
  useEffect(() => {
    setTimeout(() => {
      setTableHeight(window.innerHeight - 163);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenMode]);

  return (
    <div className="cashflow">
      <ContentTop>
        <div className="flex">
          <CustomButton
            className="content-top-btn"
            height={38}
            onClick={generateExcel}
          >
            <div className="content-top-btn-content">
              <DownloadOutlined /> {t("common.downloadExcel")}
            </div>
          </CustomButton>
          <FilterAccount />
          <FilterDate format="year" />
        </div>
      </ContentTop>

      <div className="table">
        <Table
          scroll={{ x: 1200, y: tableHeight }}
          bordered={true}
          columns={cashFlowColumns(t)}
          dataSource={data}
          pagination={false}
          loading={isLoading}
          className="cashflow_table"
        />
      </div>
    </div>
  );
}

export default CashFlowPage;

import { TFunctionType } from "src/static/constants";

const sub_statistic_keys = ["cashflowStats", "pnlStats"];
const sub_check_keys = ["unConfirmed", "pending", "edited"];
const sub_cash_keys = ["cash", "monitor", "transfer"];
const sub_control_reports_keys = ["cashflow", "pnl", "balance"];
const sub_helper_reports_keys = [
  "payment_calendar",
  "salary",
  "commitment",
  "akt",
  "debtor_creditor",
  "basic_tools",
  "purchase",
  "storage",
];
const sub_info_keys = ["currency", "source", "account", "direction", "team", "contingent"];

const sub_budget_cashflow_keys = ["budget_cashflow_list", "budget_cashflow_by_month"];
const sub_budget_pnl_keys = ["budget_pnl_list", "budget_pnl_by_month"];
const sub_planning_keys = [...sub_budget_cashflow_keys, ...sub_budget_pnl_keys];

export const findOpenKeys = (path: string | string[] | undefined) => {
  if (typeof path === "string") {
    if (sub_statistic_keys.includes(path)) return "sub_control_panel";
    else if (sub_check_keys.includes(path)) return "sub_check";
    else if (sub_cash_keys.includes(path)) return "sub_cash";
    else if (sub_info_keys.includes(path)) return "sub_info";
    else if (sub_control_reports_keys.includes(path)) return "sub_control_reports";
    else if (sub_helper_reports_keys.includes(path)) return "sub_helper_reports";
    else if (sub_planning_keys.includes(path)) return "sub_planning";
  }
};

export const findChildrenOpenKeys = (path: string | string[] | undefined) => {
  if (typeof path === "string") {
    if (sub_budget_cashflow_keys.includes(path)) return "sub_budget_cashflow";
    else if (sub_budget_pnl_keys.includes(path)) return "sub_budget_pnl";
  }
};

export const findDashboardPageTitle = (path: string, t: TFunctionType) => {
  if (path === "balance") return t("subCash.balans");
  else if (path === "profit") return t("subControlPanel.income");
  else if (path === "revenue") return t("subControlPanel.revenue");
  else if (path === "portion") return t("common.directions");
  else if (path === "expenses") return t("subControlPanel.expenses");
  else if (path === "team") return t("common.users");
  else if (path === "monitor") return t("sidebar.cash.menu2");
  else if (path === "transfer") return t("sidebar.cash.menu3");
  else if (path === "unConfirmed") return t("subCheck.uncorfirmed");
  else if (path === "pending") return t("subCheck.unAnswered");
  else if (path === "edited") return t("sidebar.check.menu1");
  else if (path === "cash") return t("sidebar.cash.title");
  else if (path === "cashflow") return t("sidebar.cashflow.title");
  else if (path === "source") return t("sidebar.info.menu2");
  else if (path === "account") return t("sidebar.info.menu3");
  else if (path === "company") return t("sidebar.kompany.title");
  else if (path === "contingent") return t("sidebar.info.menu6");
  else if (path === "direction") return t("common.directions");
  else if (path === "currency") return t("sidebar.info.menu1");
  else if (path === "commitment") return t("sidebar.pnl.menu2");
  else if (path === "akt") return t("sidebar.pnl.menu3");
  else if (path === "f_calendar") return t("sidebar.pnl.f_calendar");
  else if (path === "profit_and_soft") return t("subControlPanel.profit_soft");
  else if (path === "overhead") return t("subControlPanel.overhead");
  else if (path === "gross") return t("common.grosProfit");
  else if (path === "operative") return t("common.operativProfit");
  else if (path === "soft") return t("common.netProfit");
  else if (path === "pnl") return t("sidebar.pnl.menu1");
  else if (path === "cashflowStats") return t("sidebar.pnl.statistcCashflow");
  else if (path === "pnlStats") return t("sidebar.pnl.statistcPnl");
  else if (path === "debtor_creditor") return t("sidebar.pnl.debitor");
  else if (path === "purchase") return t("subHelper.purchase");
  else if (path === "basic_tools") return t("subHelper.mainToll");
  else if (path === "salary") return t("subHelper.salary");
  else if (path === "storage") return t("subHelper.storage");
  else if (path === "payment_calendar") return t("subHelper.payment_calendar");
  //Planning
  else if (path === "budget_cashflow_list") return t("sidebar.planned.menu1");
  else if (path === "budget_cashflow_by_month") return t("sidebar.planned.menu2");
  else if (path === "budget_pnl_list") return t("subCash.budget_pnl_list");
  else if (path === "budget_pnl_by_month") return t("subCash.budget_pnl_by_month");
};

export const noPaddingPathes = ["/dashboard", "/dashboard/"];
const mobileFullPathes = ["balance", "profit", "revenue", "cash", "cashflow", "source", "account"];

export const findMobileFull = (path: string) => {
  if (mobileFullPathes.includes(path)) return "dashboard-content-mobile-full";
  else return "";
};

export const basicTarifMenuKeys = [
  "",
  "cashflowStats#balance",
  ...sub_check_keys,
  ...sub_cash_keys,
  "cashflow",
  ...sub_budget_cashflow_keys,
  ...sub_info_keys,
  "company",
  "sub_control_panel",
  "sub_check",
  "sub_cash",
  "sub_info",
  "sub_control_reports",
  "sub_planning",
  "sub_budget_cashflow",
];

import CountUp from "react-countup";
import CashCard from "./components/CashCard";
import Filter from "./components/Filter";
import PlanFactCard from "./components/PlanFactCard";
import "./dashboard.scss";
import useDashboard, {
  DirectionAttributes,
  PnlTypeAttributes,
} from "./useDashboard";
import { useTypedSelector } from "src/app/store";
import { useTranslation } from "react-i18next";
import useParamsHook from "src/hooks/params";
import { PaymentCalendarCard } from "./components/PaymentCalendarCard";

function DashboardPage() {
  const { t } = useTranslation();
  const { data, pnlData, accountsData, onCashflowFilter, onPnlFilter } =
    useDashboard();
  const { searchParams } = useParamsHook();
  const { darkMode } = useTypedSelector((state) => state.layout);
  const { profile } = useTypedSelector((state) => state.users);
  const tariff = profile?.subscription_plan.code;

  return (
    <div className={`dashboardPage ${darkMode && "dashboardPage-dark"}`}>
      <Filter />

      {!searchParams.get("company_id") && (
        <div className="dashboardPage-statistics-cards-wrap">
          <div className="dashboardPage-statistics-cards">
            <div className="statistics-card">
              <div className="statistics-card-top">
                <p>{t("common.all")}</p>
              </div>
              <div className="statistics-card-body">
                <CountUp
                  start={0}
                  end={accountsData?.total_balance || 0}
                  separator=" "
                  duration={2}
                  useEasing={true}
                />
                <p>{t("common.uzs")}</p>
              </div>
            </div>
            {accountsData?.others.map((el, i) => (
              <div className="statistics-card" key={i}>
                <div className="statistics-card-top">
                  <p>{el.title}</p>
                </div>
                <div className="statistics-card-body">
                  <CountUp
                    start={0}
                    end={el.balance || 0}
                    separator=" "
                    duration={2}
                    useEasing={true}
                  />
                  <p>{t(`common.${el.currency}`)}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="dashboardPage-cards">
        <PlanFactCard
          title={t("dashboard.incomeFact")}
          fact={data?.income_fact}
          plan={data?.income_plan}
          onFilterChange={(val) =>
            onCashflowFilter(val as DirectionAttributes, "income")
          }
        />
        <PlanFactCard
          title={t("dashboard.outcomeFact")}
          fact={data?.outcome_fact}
          plan={data?.outcome_plan}
          onFilterChange={(val) =>
            onCashflowFilter(val as DirectionAttributes, "outcome")
          }
        />
        <CashCard
          title={t("dashboard.restFact")}
          amount={data?.income_remaining}
          onFilterChange={(val) =>
            onCashflowFilter(val as DirectionAttributes, "remaining")
          }
        />

        {tariff !== "basic" && (
          <>
            <PlanFactCard
              title={t("dashboard.daromadFact")}
              fact={pnlData?.income_fact}
              plan={pnlData?.income_plan}
              type="pnl"
            />
            <PlanFactCard
              title={t("dashboard.xarajatFact")}
              fact={pnlData?.expence_fact}
              plan={pnlData?.expence_plan}
              type="pnl_expence"
              onFilterChange={(val) =>
                onPnlFilter(val as PnlTypeAttributes, "expence")
              }
            />
            <PlanFactCard
              title={t("dashboard.foydaFact")}
              fact={pnlData?.benefit_fact}
              plan={pnlData?.benefit_plan}
              type="pnl"
              onFilterChange={(val) =>
                onPnlFilter(val as PnlTypeAttributes, "benefit")
              }
            />
          </>
        )}

        <PaymentCalendarCard />

        {/* <RemainsCard /> */}
        {/* <ExpensesCard /> */}
        {/* <PaymentCard /> */}
      </div>
    </div>
  );
}

export default DashboardPage;

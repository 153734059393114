export const languageRu = {
  home: {
    hero: {
      title1: "Финансы без учета",
      title2: "= теряемые деньги",
      description:
        "Если вы не будете правильно управлять своими деньгами с сегодняшнего дня, завтра нехватка денег будет управлять вами",
      button1: "Войти в систему",
      button2: "Оставьте свои контакты",
    },
    explain: {
      title1: "Финансовые расчеты",
      title2: "сложный процесс",
      card1Title: "Стратегическое планирование с динамическими панелями",
      card1Text:
        "Составляйте подробные финансовые планы и отслеживайте финансовые показатели вашей компании в реальном времени, что упростит принятие решений и планирование для продолжительного успеха",
      card2Title: "Точно вычисляйте чистую прибыль",
      card2Text:
        "Полностью управляйте своими финансовыми данными и точно вычисляйте чистую прибыль. Добавляйте материальные и нематериальные потоки доходов к вашим анализам, чтобы еще более точно определить вашу финансовую стабильность и стратегически планировать.",
      card3Title: "Персонализированный доступ",
      card3Text:
        "Адаптируйте видимость и функциональность в зависимости от роли каждого сотрудника, что позволит членам команды сосредоточиться на информации и задачах, соответствующих их обязанностям",
      card4Title: "Искусственный интеллект понимает ваш бизнес",
      card4Text:
        "Используя возможности искусственного интеллекта, получите персонализированные финансовые советы, соответствующие финансовому состоянию вашей компании. Наша умная система понимает ваше финансовое положение и предлагает идеи и рекомендации для оптимизации вашей финансовой стратегии.",
      card5Title: "Точно анализируйте ваше финансовое состояние",
      card5Text:
        "Полностью отслеживайте финансовое состояние вашего бизнеса. Глубоко анализируйте свои финансовые показатели с помощью широкого набора таблиц и линейных графиков, улучшайте процесс принятия решений с помощью подробных анализов.",
      card6Title: "Подтверждение финансовых поступлений и расходов",
      card6Text:
        "Aqcham Telegram Bot получает уведомления о расходах и доходах команды, что повышает подотчетность и прозрачность в вашей команде.",
    },
    about: {
      title1: "Прямо в программе",
      title2: "преимущества",
      card1Title: "Связано с ChatGPT 4",
      card2Title: "Автоматические анализы",
      card3Title: "Финансовые консультации",
      card4Title: "Бесплатные учебники по финансам",
      card5Title: "Удобная панель управления",
      card6Title: "Видеоуроки по использованию программы",
    },
    priority: {
      title1: "Aqcham - финансовая модель,",
      title2: "работающая с использованием искусственного интеллекта",
      description:
        "Не будет ошибок и недостатков. Финансовые возможности не ограничены простыми и скучными шаблонами",
      card1line1: "Все данные собираются в одном месте",
      card1line2: "Автоматические расчеты",
      card1line3:
        "Автоматически созданные графики и готовые визуальные отчеты на панели управления",
      card1line4: "Оптимизация с помощью искусственного интеллекта",
      card1line5:
        "Можно восстановить утерянные операции. Все данные хранятся на сервере",
      card1line6:
        "Предотвращение дефицита наличных и создание финансовых прогнозов на любые периоды заранее",
      card2line1: "Сложность интерфейса.",
      card2line2: "Некоторая медлительность расчетов.",
      card2line3: "Нет творческой свободы.",
      card2line4: "Сложность ввода и получения данных",
      card2line5: "Медленная скорость расчетов",
      card2line6: "Из-за этого увеличивается количество документов и отчетов.",
      card3line1: "Слишком много таблиц",
      card3line2: "Ошибки в расчетах из-за неверных формул",
      card3line3:
        "Сложность анализа данных и ограниченные возможности отчетности",
      card3line4: "Ручной ввод данных",
      card3line5: "Низкий уровень безопасности",
      card3line6: "Невозможно составить план денежных потоков в Excel",
    },
    team: {
      title: "Наша команда",
      card1Title: "ГОДОВОЙ ОПЫТ",
      card2Title: "ФИНАНСОВЫЕ МОДЕЛИ",
      card3Title: "ФИНАНСОВЫЕ ДИРЕКТОРА",
      card4Title: "ПРОЕКТ",
    },
    partners: {
      title1: "Нам доверяют",
      title2: "наши партнеры",
    },
    contact: {
      title1: "в любое время",
      title2: "свяжитись с нами",
      formTitle: "Оставьте свой номер, и мы свяжемся с вами",
      button: "Отправить",
    },
    footer: {
      description:
        "Онлайн-программа для управления финансами. С помощью этой программы вы управляете деньгами, доходами и прибылью!",
      linkTitle: "Ссылки",
      blogTitle: "Блог",
      blogLine1: "Условия использования",
      blogLine2: "Политика конфиденциальности",
      adressTitle: "НАШ АДРЕС",
      adressLine1: "Город Ташкент, улица Шота Руставели, дом 1, здание МФактор",
      contactButton: "Свяжитесь с нами",
    },
    menu: {
      menu1: "О программе",
      menu2: "Возможности",
      menu3: "Партнеры",
      menu4: "Цены",
      menu5: "Контакт",
    },
  },
  auth: {
    signUp: {
      title1: "Добро пожаловать",
      title2: "в AQCHAM",
      description:
        "Если вы не знаете точные цифры в бизнесе, не знакомы с отчетами о прибыли и убытках, доходах и расходах, вы точно потерпите убытки",
      formTitle: "Добро пожаловать в AQCHAM",
      firstNameLabel: "Имя",
      firstNamePlaceholder: "Ваше имя",
      lastNameLabel: "Фамилия",
      lastNamePlaceholder: "Ваша фамилия",
      emailLabel: "Электронная почта",
      emailPlaceholder: "Ваш email",
      passwordLabel: "Пароль",
      passwordPlaceholder: "Ваш пароль",
      confirmPasswordLabel: "Подтвердите пароль",
      confirmPasswordPlaceholder: "Подтвердите пароль",
      bottomText: "Если у вас есть учетная запись “AQCHAM“?",
      button: "Регистрация",
      signInLink: "Войти в систему",
    },
    signIn: {
      formTitle: "Добро пожаловать!",
      emailLabel: "Электронная почта",
      emailPlaceholder: "Ваш email",
      passwordLabel: "Пароль",
      passwordPlaceholder: "Введите пароль",
      forgetPassword: "Забыли пароль?",
      button: "Войти",
      bottomText: "Если у вас нет учетной записи “AQCHAM“?",
      signInLink: "Регистрация",
      commentLink: "Оставить запрос",
    },
    comment: {
      businessLabel: "О вашем бизнесе",
      send: "Отправить",
      phone: "Телефонный номер",
    },
  },
  sidebar: {
    statistics: {
      title: "Панель управления",
      menu1: "Cashflow",
      menu2: "Прибыль и убытки",
    },
    check: {
      title: "Подтвердить",
      menu1: "Отредактировано",
    },
    cash: {
      title: "Касса",
      menu1: "Кассовая книга",
      menu2: "Кассовый вывод",
      menu3: "Перевод",
    },
    cashflow: {
      title: "Денежный поток",
      selectTitle: "Выберите денежный поток",
    },
    pnl: {
      title: "Прибыль и убытки",
      menu1: "Прибыль и убытки",
      menu2: "Обязанности",
      menu3: "АКТ",
      f_calendar: "Финансовый календарь",
      statistcCashflow: "Статистика Cashflow",
      statistcPnl: "Статистика прибыли и убытков",
      debitor: "Дебиторы и кредиторы",
    },
    planned: {
      title: "Планирование",
      menu1: "План поступления и списания",
      menu2: "Бюджет денежного потока",
    },
    info: {
      title: "Данные",
      menu1: "Валюты",
      menu2: "Источники",
      menu3: "Счета",
      menu4: "Направления",
      menu5: "Сотрудники",
      menu6: "Контрагенты",
    },
    kompany: {
      title: "Компания",
    },
    branchs: {
      title: "Филиалы",
    },
  },
  common: {
    all: "Итого",
    uzs: "сум",
    rubl: "рубль",
    name: "Название",
    view: "Просмотр",
    enterName: "Введите название",
    dollar: "доллар",
    income: "Поступления",
    outcome: "Списания",
    currency: "Валюта",
    currencyRate: "Курс валюты",
    currencyRateForDollar: "Курс валюты ($1 за)",
    transfer: "Перевод",
    today: "Сегодня",
    thisMonth: "Этот месяц",
    lastMonth: "Прошлый месяц",
    difference: "Разница",
    date: "Дата",
    intervalDate: "Промежуточная дата",
    actions: "Действия",
    plan: "План",
    home: "Главная страница",
    dashboard: "Панель управления",
    fact: "Факт",
    percent: "Процент",
    profit: "Выручка",
    comment: "Примечание",
    enterComment: "Введите примечание",
    total: "Общий",
    barchasi: "Все",
    directions: "Направления",
    direction: "Направление",
    search: "Поиск",
    clear: "Очистить",
    currentMoneyFlow: "Текущий денежный поток",
    financialMoneyFlow: "Изменение финансового денежного потока",
    invistationMoneyFlow: "Изменение инвестиционного денежного потока",
    naklodnoyOutcome: "Накладные расходы",
    administrativeOutcome: "Административные расходы",
    commersialOutcome: "Коммерческие расходы",
    expense: "Расходы",
    expenseType: "Тип расходов",
    subSources: "Субстатья",
    position: `Позиция`,
    enterSubSources: "Субстатья",
    taxes: "Налоги",
    users: "Сотрудники",
    user: "Сотрудник",
    addUuser: `Добавить сотрудника`,
    month: "Месяц",
    monthly: "Месячный",
    whichMonth: "Какой месяц",
    whichMonthInfo: "За какой месяц сотрудник получил зарплату?",
    value: "Себестоимость",
    enterValue: "Введите себестоимость",
    netProfit: "Чистая прибыль",
    grosProfit: "Валовая прибыль",
    operativProfit: "Операционная прибыль",
    morjanalProfit: "Маржинальная прибыль",
    totalBalance: "Общий остаток",
    paymentDifference: "Разница в оплате",
    accounts: "Счета",
    sources: "Источники",
    source: "Источник",
    Usource: "Источник",
    sourceType: "Тип источника",
    addsource: "Добавить источник",
    entersource: "Введите название источника",
    editsource: "Изменить источник",
    selectAccount: "Выберите номер счета",
    account: "Номер счета",
    contingents: "Контрагенты",
    contingent: "Контрагент",
    addcontingent: `Добавление нового контрагента`,
    typeContingent: "Тип контрагента",
    selectContingent: "Выберите контрагента",
    addContingent: "Добавить контрагента",
    nameContingent: "Название контрагента",
    enterContingent: "Введите название контрагента",
    selectYear: "Выберите год",
    selectDate: "Выберите дату",
    selectMonth: "Выберите месяц",
    selectSource: "Выберите источник",
    selectCurrency: "Выберите валюту",
    enterCurrencyRate: "Введите курс валюты",
    selectUser: "Выберите сотрудника",
    more: "Подробности",
    filter: "Фильтр",
    check: "Проверить",
    save: "Сохранить",
    cancel: "Отмена",
    downloadExcel: "Загрузить Excel",
    amount: "Количество",
    enterAmount: "Введите количество",
    add: "Добавить",
    _add: "Добавить",
    enterPrice: "Введите цену",
    remain: "Баланс",
    entitlement: "Право",
    deposit: "Депозит",
    logout: "Выйти из системы",
    doTransfer: "Перевод",
    editInfo: ` редактировать данные`,
    priceSum: "Цена (сум)",
    successMessage: "Добавлено успешно",
    allBranchsStat: "Вся статистика",
  },
  months: {
    jan: "Январь",
    feb: "Февраль",
    march: "Март",
    apr: "Апрель",
    may: "Май",
    jun: "Июнь",
    jul: "Июль",
    aug: "Август",
    sep: "Сентябрь",
    oct: "Октябрь",
    nov: "Ноябрь",
    dec: "Декабрь",
  },
  dashboard: {
    title: "Панель управления",
    incomeFact: "План и факт поступления",
    outcomeFact: "План и факт списания",
    restFact: "Общее изменение",
    daromadFact: "План и факт выручка",
    xarajatFact: "План и факт рассходы",
    foydaFact: "План и факт прибыли",
    paymentYear: "Календарь платежей на год",
    paymentCalendar: "Платежный календарь",
  },
  subControlPanel: {
    title: "Управленческие отчеты",
    balance: "Остаток наличных",
    expenses: "Доли источника",
    income: "Притоки и оттоки",
    revenue: "Доли денежного потока",
    profit_soft: "Выручка и чистая прибыль",
    overhead: "Маржинальность",
    sourceMontly: "Источник/Месяцы",
  },
  subCheck: {
    uncorfirmedAcc: "Неконфирмованные счета",
    uncorfirmed: "Неконфирмованный",
    unAnswered: "Без ответа",
  },
  subCash: {
    historyCash: "История кассы",
    historyTransfer: "История переводов",
    exitCash: "Кассовый выход",
    from: "Из аккаунта",
    to: "На аккаунт",
    size: "Переведенная сумма",
    transferAmount: "Сумма перевода",
    enterTransferAmount: "Сумма перевода",
    transferDate: "Дата перевода",
    toTransferDate: "Дата перевода",
    selectToAcc: "Выберите входящий счет",
    selectFromAcc: "Выберите исходящий счет",
    hisoboti: "Отчет",
    exactAccount: "Соответствующий номер счета",
    exactAccountBalance: "Остаток на соответствующем счете",
    enterExactAccountBalance: "Введите остаток на соответствующем счете!",
    system: "Система",
    difesit: "Недостача",
    bankBalance: "Остаток в банке",
    systemBalance: "Остаток в системе",
    startMonthBalance: "Остаток на начало месяца",
    currentBalance: "Изменения текушый денежных потоков",
    financialBalance: "Изменения финансовых потоков",
    invsetialBalance: "Изменения инвестиционного денежного потока",
    changeAccount: "Обмен счетов",
    total_change: "Общие изменения",
    remains_last: "Остаток на конец месяца",
    profit: "Выручка",
    enterProfit: "Введите выручка",
    profitPercent: "Процент от выручка",
    marginProfit: "Маржинальный доход",
    marginPercent: "Процент маржинальности",
    overheadOutcome: "Постоянные расходы",
    grossAll: "Валовая прибыль (+)/Убыток (-)",
    grossRentabl: "Рентабельность валовой прибыли %",
    operativeAll: "Операционная прибыль (+)/Убыток (-)",
    operativRentabl: "Рентабельность операционной прибыли %",
    deprecation: "Амортизация ОС (Основные средства)",
    sof: "Чистая прибыль (+)/Убыток (-)",
    sofRentabl: "Рентабельность чистой прибыли %",
    remansTitle: "В конце месяца в валюте",
    mainTools: "Основные средства",
    partProfit: "Нераспределенная прибыль",
    warehause: "Склад",
    material: "Сырьё",
    halfProduct: "Полуфабрикаты",
    product: "Готовая продукция",
    debit: "Дебиторка",
    credit: "Кредиторка",
    duties: "Обязанности",
    suplier: "От поставщиков",
    _suplier: "Поставщик",
    customers: "От покупателей",
    customer: "Покупатель",
    selectCustomer: "Выберите покупателя",
    emplyee: "От сотрудников",
    rent: "От налога",
    moneys: "Средства",
    balans: "Баланс",
    personalCapital: "Собственный капитал",
    active: "Активы",
    passive: "Пассивы",
    workingCapital: "Оборотный капитал",
    budgetTitle: "Бюджетный денежный поток",
    budgetPnlTitle: "Бюджетная прибыль и убытки",
    budget_pnl_list: "План выручка и расходов",
    budget_pnl_by_month: "Бюджетная прибыль и убытки",
    selectAccount: "Пожалуйста, выберите один из аккаунтов!",
  },
  subHelper: {
    storage: "Хранилище",
    storageAddTitle: "Добавить информацию о хранилище",
    storageType: "Тип продукта",
    selectStorageType: "Выберите тип продукта",
    storageRowMaterials: "Сырье",
    storageSemiFinished: "Полуфабрикат",
    storageFinished: "Готовый продукт",
    salary: "Заработная плата",
    debitCredit: "Дебет-кредит",
    purchase: "Покупки",
    mainToll: "Основные средства",
    title: "Вспомогательные отчеты",
    editTitle: "Изменить заработную плату",
    addTitle: "Добавить заработную плату",
    editDutyTitle: "Изменить обязательства",
    addDutyTitle: "Добавить обязательства",
    aktNumber: "Номер акта",
    enterAktNumber: "Введите номер акта",
    aktDate: "Дата акта",
    serviceType: "Вид услуги",
    editAktTitle: "Изменить обязательства",
    addAktTitle: "Добавить обязательства",
    editPurchaseTitle: "Изменить покупку",
    addPurchaseTitle: "Добавить покупку",
    purchaseDate: "Дата покупки",
    attritionMonthly: "Амортизация (в разрезе месяцев)",
    monthly_rate: "Амортизация за месяц",
    total_months: "Сколько месяцев используется",
    current_balance: "Остаток суммы (в сумах)",
    initial_balance: "Начальная цена (в сумах)",
    editMainTitle: "Изменить основные средства",
    addMainTitle: "Добавить основные средства",
    editInformation: "Изменить данные",
    bookWithCustomers: "Расчеты с покупателями",
    madeService: "Услуга предоставлена",
    payedCustomer: "Покупатель оплатил",
    bookWithDillers: "Расчеты с поставщиками",
    buyed: "Покупка совершена",
    payed: "Оплачено",
    bookWithEmployes: "Расчеты с сотрудников",
    calculated: "Рассчитано",
  },
  subInfo: {
    initial_money: "Начальный остаток",
    start_date: `Дата начала`,
    enterStartDate: `Введите дату начала`,
    enterInitial_money: "Введите начальный остаток",
    addAccount: "Добавить номер счета",
    editAccount: "Изменить номер счета",
    nameAccount: "Название номера счета",
    enterAccount: "Введите название номера счета",
    balanceAccount: "Номер счета: начальный баланс",
    enterBalanceAccount: "Введите начальный баланс счета",
    addDirection: "Добавить направление",
    editDirection: "Изменить данные направления",
    nameDirection: "Название направления",
    enterDirection: "Введите название направления",
    phoneNumber: "Телефонный номер",
    enterCtrPhone: "Введите телефонный номер контрагента",
  },
  company: {
    alert:
      "Пожалуйста, сначала введите свою компанию для использования системы",
    addCompany: "Добавить компанию",
    nameCompany: "Название компании",
    enterCompany: "Введите название компании",
  },
  branches: {
    addBranch: `Добавить ветку`,
    nameBranch: `Название ветки`,
    enterBranch: `Введите название ветки`,
  },
};

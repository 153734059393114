import { getRootState } from "src/app/store";
import {
  ICashflowDashboardRes,
  IPnlRes,
} from "src/pages/sub_control_reports/pnl/type";
import { api } from "../api";
import { IBaseDataRes, IBaseEdit, IDeleteRes } from "../type";
import {
  IAccount,
  IAccountBalanceStat,
  IAccountRes,
  IAddAccount,
  IAddCurrency,
  IAddDirection,
  IAddJournal,
  IAddMonitor,
  IAddSource,
  IAddTransaction,
  IAkt,
  IAktRes,
  IBasicTools,
  IBasicToolsRes,
  IBudgetPnl,
  IBudgetPnlRes,
  ICashflow,
  ICategoryRes,
  ICommitment,
  ICommitmentRes,
  IConfirmJournal,
  IConfirmJournalChange,
  ICurrency,
  ICurrencyRes,
  IDebtCredit,
  IDebtCreditObj,
  IDirection,
  IDirectionRes,
  IEditAccount,
  IEditAkt,
  IEditCommitment,
  IEditCurrency,
  IEditDirection,
  IEditJournal,
  IEditMonitor,
  IEditSalary,
  IEditSource,
  IJournal,
  IJournalChangeRes,
  IJournalRes,
  IMonitor,
  IMonitorRes,
  IPCbetween,
  IPnlProfitSoft,
  IPurchase,
  IPurchaseRes,
  IRemaining,
  ISalary,
  ISalaryRes,
  ISource,
  ISourceParentRes,
  ISourceRes,
  IStatRes,
  ITransaction,
  ITransactionRes,
} from "./type";

export const financeApi = api.injectEndpoints({
  endpoints: (build) => ({
    //// Dashboard ////
    getAccountBalancesStat: build.mutation<IAccountBalanceStat, string>({
      query: (params) => ({
        url: `/finance/diagrams/monthly-balance?${params}`,
      }),
    }),
    getCashflowDashboard: build.mutation<ICashflowDashboardRes, string>({
      query: (params) => ({
        url: `/finance/diagrams/cashflow-dashboard?${params}`,
      }),
    }),
    //// Diagrams ////
    getPnl: build.mutation<IPnlRes, string>({
      query: (params) => ({ url: `/finance/diagrams/pnl?${params}` }),
    }),
    getPnlStat: build.mutation<any, string>({
      query: (params) => ({ url: `/finance/diagrams/pnl-diagram?${params}` }),
    }),
    getPnlProfitSoftStat: build.mutation<IPnlProfitSoft, string>({
      query: (params) => ({ url: `/finance/diagrams/pnl-diagram?${params}` }),
    }),
    getCashBalanceStat: build.mutation<IStatRes, string>({
      query: (params) => ({ url: `/finance/diagrams/cash-balance?${params}` }),
    }),
    getIncomeOutcomeStat: build.mutation<
      IStatRes,
      { params: string; type: string }
    >({
      query: ({ params, type }) => ({
        url: `/finance/diagrams/income-outcome${
          type === "source" ? "-sources" : ""
        }?${params}`,
      }),
    }),
    getAccountsStat: build.mutation<IStatRes[], string>({
      query: (params) => ({ url: `/finance/diagrams/directions?${params}` }),
    }),
    getSourcesStat: build.mutation<IStatRes[], string>({
      query: (params) => ({ url: `/finance/diagrams/sources?${params}` }),
    }),
    getDirectionsStat: build.mutation<IStatRes[], string>({
      query: (params) => ({
        url: `/finance/diagrams/directions-monthly?${params}`,
      }),
    }),
    getSourcesOutcomeStat: build.mutation<IStatRes[], string>({
      query: (params) => ({ url: `/finance/diagrams/categories?${params}` }),
    }),
    getCashflow: build.mutation<ICashflow, string>({
      query: (params) => ({ url: `/finance/diagrams/cashflow?${params}` }),
    }),
    generateCashflow: build.mutation<{ link: string }, void>({
      query: () => ({ url: `/finance/diagrams/cashflow?export=true` }),
    }),
    getCashflowPredicted: build.mutation<ICashflow, string>({
      query: (params) => ({
        url: `/finance/diagrams/prediction-cashflow?${params}`,
      }),
    }),

    //Get category endpoint
    getCategories: build.query<ICategoryRes, void>({
      query: () => ({ url: "/finance/category" }),
    }),

    ////////// Account //////////

    //Get account endpoint
    getAccountAll: build.query<IAccountRes, void>({
      query: () => ({ url: `/finance/account` }),
    }),
    getAccount: build.mutation<IAccountRes, string>({
      query: (params) => ({ url: `/finance/account?${params}` }),
    }),
    //Add account endpoint
    addAccount: build.mutation<IAccount, IAddAccount>({
      query: (body) => ({
        url: "/finance/account",
        method: "POST",
        body: { ...body, company: getRootState().users?.profile?.company?.id },
      }),
    }),
    //Edit account endpoint
    editAccount: build.mutation<IAccount, IEditAccount>({
      query: (data) => {
        let { id, body } = data;
        return {
          url: "/finance/account/" + id,
          method: "PUT",
          body: {
            ...body,
            company: getRootState().users?.profile?.company?.id,
          },
        };
      },
    }),
    //Delete account endpoint
    deleteAccount: build.mutation<IDeleteRes, number>({
      query: (id) => ({
        url: "/finance/account/" + id,
        method: "DELETE",
      }),
    }),

    ////////// Source //////////

    //Get source endpoint
    getSourceAll: build.query<ISourceRes, void>({
      query: () => ({ url: "/finance/source" }),
    }),
    getSource: build.mutation<ISourceRes, string>({
      query: (params) => ({ url: `/finance/source?${params}` }),
    }),
    getSourceParent: build.query<ISourceParentRes, void>({
      query: () => ({ url: `/finance/source-parent-category?limit=10000` }),
    }),
    //Add source endpoint
    addSource: build.mutation<ISource, IAddSource>({
      query: (body) => ({
        url: "/finance/source",
        method: "POST",
        body: { ...body, company: getRootState().users?.profile?.company?.id },
      }),
    }),
    //Edit source endpoint
    editSource: build.mutation<ISource, IEditSource>({
      query: (data) => {
        let { id, body } = data;
        return {
          url: "/finance/source/" + id,
          method: "PUT",
          body: {
            ...body,
            company: getRootState().users?.profile?.company?.id,
          },
        };
      },
    }),
    //Delete Source endpoint
    deleteSource: build.mutation<IDeleteRes, number>({
      query: (id) => ({
        url: "/finance/source/" + id,
        method: "DELETE",
      }),
    }),

    ////////// Journal //////////

    //Get journal endpoint
    getJournalAll: build.query<IJournalRes, void>({
      query: () => ({ url: "/finance/journal" }),
    }),
    getJournal: build.mutation<IJournalRes, string>({
      query: (params) => ({ url: `/finance/journal?${params}` }),
    }),
    //Generate journal endpoint
    generateJournal: build.mutation<{ link: string }, void>({
      query: () => ({ url: `/finance/journal/export` }),
    }),
    //Add journal endpoint
    addJournal: build.mutation<IJournal, IAddJournal>({
      query: (body) => ({
        url: "/finance/journal",
        method: "POST",
        body,
      }),
    }),

    //Edit journal endpoint
    editJournal: build.mutation<IJournal, IEditJournal>({
      query: (body) => ({
        url: "/finance/journal-change",
        method: "POST",
        body,
      }),
    }),
    //Confirm journal change endpoint
    confirmJournal: build.mutation<IConfirmJournal, number>({
      query: (id) => ({
        url: "/finance/journal/" + id,
        method: "PATCH",
        body: { status: "confirmed" },
      }),
    }),
    //Delete journal endpoint
    deleteJournal: build.mutation<IDeleteRes, number>({
      query: (id) => ({
        url: "/finance/journal/" + id,
        method: "DELETE",
      }),
    }),

    ////////// Journal prediction //////////

    //Get journal prediction endpoint
    getJournalPredicted: build.mutation<IJournalRes, string>({
      query: (params) => ({ url: `/finance/prediction-journal?${params}` }),
    }),
    //Generate journal prediction endpoint
    generateJournalPredicted: build.mutation<{ link: string }, void>({
      query: () => ({ url: `/finance/prediction-journal/export` }),
    }),
    //Add journal prediction endpoint
    addJournalPredicted: build.mutation<IJournal, IAddJournal>({
      query: (body) => ({
        url: "/finance/prediction-journal",
        method: "POST",
        body,
      }),
    }),
    //Edit journal prediction endpoint
    editJournalPredicted: build.mutation<IJournal, IEditJournal>({
      query: (body) => ({
        url: "/finance/prediction-journal/" + body.cashflow,
        method: "PATCH",
        body,
      }),
    }),
    //Delete journal prediction endpoint
    deleteJournalPredicted: build.mutation<IDeleteRes, number>({
      query: (id) => ({
        url: "/finance/prediction-journal/" + id,
        method: "DELETE",
      }),
    }),

    //Get journal change endpoint
    getJournalChange: build.mutation<IJournalChangeRes, string>({
      query: (params) => ({ url: `/finance/journal-change?${params}` }),
    }),

    //Confirm journal change endpoint
    confirmJournalChange: build.mutation<IConfirmJournalChange, number>({
      query: (id) => ({
        url: "/finance/journal-change/" + id,
        method: "PATCH",
        body: { is_accepted: true },
      }),
    }),
    //Delete journal change endpoint
    deleteJournalChange: build.mutation<IDeleteRes, number>({
      query: (id) => ({
        url: "/finance/journal-change/" + id,
        method: "DELETE",
      }),
    }),

    ////////// Journal payment calendar //////////

    //Get journal  payment calendar
    getJournalPaymentCalendar: build.mutation<IJournalRes, string>({
      query: (params) => ({ url: `/finance/payment-calendar?${params}` }),
    }),
    //Add journal  payment calendar
    addJournalPaymentCalendar: build.mutation<IJournal, IAddJournal>({
      query: (body) => ({
        url: "/finance/payment-calendar",
        method: "POST",
        body,
      }),
    }),
    //Edit journal prediction endpoint
    editJournalPaymentCalendar: build.mutation<IJournal, IEditJournal>({
      query: (body) => ({
        url: "/finance/payment-calendar/" + body.cashflow,
        method: "PATCH",
        body,
      }),
    }),
    //Delete journal prediction endpoint
    deleteJournalPaymentCalendar: build.mutation<IDeleteRes, number>({
      query: (id) => ({
        url: "/finance/payment-calendar/" + id,
        method: "DELETE",
      }),
    }),
    getPCbetween: build.mutation<IPCbetween, string>({
      query: (params) => ({
        url: `/finance/payment-calendar/between?${params}`,
      }),
    }),

    ////////// Transaction //////////
    getTransactions: build.mutation<ITransactionRes, string>({
      query: (params) => ({ url: `/finance/transaction?${params}` }),
    }),
    addTransaction: build.mutation<ITransaction, IAddTransaction>({
      query: (body) => ({
        url: "/finance/transaction",
        method: "POST",
        body,
      }),
    }),

    ////////// Source //////////

    //Get source endpoint
    getMonitor: build.mutation<IMonitorRes, string>({
      query: (params) => ({ url: `/finance/cash-monitor?${params}` }),
    }),
    //Add Monitor endpoint
    addMonitor: build.mutation<IMonitor, IAddMonitor>({
      query: (body) => ({
        url: "/finance/cash-monitor",
        method: "POST",
        body: { ...body, user: getRootState().users?.profile?.id },
      }),
    }),
    //Edit Monitor endpoint
    editMonitor: build.mutation<IMonitor, IEditMonitor>({
      query: (data) => {
        let { id, body } = data;
        return {
          url: "/finance/cash-monitor/" + id,
          method: "PUT",
          body: { ...body, user: getRootState().users?.profile?.id },
        };
      },
    }),
    //Delete Monitor endpoint
    deleteMonitor: build.mutation<IDeleteRes, number>({
      query: (id) => ({
        url: "/finance/cash-monitor/" + id,
        method: "DELETE",
      }),
    }),

    ////////// Direction //////////

    //Get source endpoint
    getDirectionAll: build.query<IDirectionRes, void>({
      query: () => ({ url: `/finance/directions?limit=1000` }),
    }),
    getDirection: build.mutation<IDirectionRes, string>({
      query: (params) => ({ url: `/finance/directions?${params}` }),
    }),
    //Add Direction endpoint
    addDirection: build.mutation<IDirection, IAddDirection>({
      query: (body) => ({
        url: "/finance/directions",
        method: "POST",
        body,
      }),
    }),
    //Edit Direction endpoint
    editDirection: build.mutation<IDirection, IEditDirection>({
      query: (data) => {
        let { id, body } = data;
        return {
          url: "/finance/directions/" + id,
          method: "PUT",
          body,
        };
      },
    }),
    //Delete Direction endpoint
    deleteDirection: build.mutation<IDeleteRes, number>({
      query: (id) => ({
        url: "/finance/directions/" + id,
        method: "DELETE",
      }),
    }),

    ////////// Currency //////////

    //Get source endpoint
    getCurrency: build.mutation<ICurrencyRes, string>({
      query: (params) => ({ url: `/finance/currencies?${params}` }),
    }),
    //Add Currency endpoint
    addCurrency: build.mutation<ICurrency, IAddCurrency>({
      query: (body) => ({
        url: "/finance/currencies",
        method: "POST",
        body,
      }),
    }),
    //Edit Currency endpoint
    editCurrency: build.mutation<ICurrency, IEditCurrency>({
      query: (data) => {
        let { id, body } = data;
        return {
          url: "/finance/currencies/" + id,
          method: "PATCH",
          body,
        };
      },
    }),
    //Delete Currency endpoint
    deleteCurrency: build.mutation<IDeleteRes, number>({
      query: (id) => ({
        url: "/finance/currencies/" + id,
        method: "DELETE",
      }),
    }),

    ////////// Salary //////////

    //Get source endpoint
    getSalary: build.mutation<ISalaryRes, string>({
      query: (params) => ({ url: `/finance/salary?${params}` }),
    }),
    //Add Salary endpoint
    addSalary: build.mutation<ISalary, ISalary>({
      query: (body) => ({
        url: "/finance/salary",
        method: "POST",
        body,
      }),
    }),
    //Edit Salary endpoint
    editSalary: build.mutation<ISalary, IEditSalary>({
      query: (data) => {
        let { id, body } = data;
        return {
          url: "/finance/salary/" + id,
          method: "PATCH",
          body,
        };
      },
    }),
    //Delete Salary endpoint
    deleteSalary: build.mutation<IDeleteRes, number>({
      query: (id) => ({
        url: "/finance/salary/" + id,
        method: "DELETE",
      }),
    }),

    ////////// Commitment //////////

    //Get source endpoint
    getCommitment: build.mutation<ICommitmentRes, string>({
      query: (params) => ({ url: `/finance/commitment?${params}` }),
    }),
    //Add Commitment endpoint
    addCommitment: build.mutation<ICommitment, ICommitment>({
      query: (body) => ({
        url: "/finance/commitment",
        method: "POST",
        body,
      }),
    }),
    //Edit Commitment endpoint
    editCommitment: build.mutation<ICommitment, IEditCommitment>({
      query: (data) => {
        let { id, body } = data;
        return {
          url: "/finance/commitment/" + id,
          method: "PATCH",
          body,
        };
      },
    }),
    //Delete Commitment endpoint
    deleteCommitment: build.mutation<IDeleteRes, number>({
      query: (id) => ({
        url: "/finance/commitment/" + id,
        method: "DELETE",
      }),
    }),

    ////////// Akt //////////

    //Get source endpoint
    getAkt: build.mutation<IAktRes, string>({
      query: (params) => ({ url: `/finance/akt?${params}` }),
    }),
    //Add Akt endpoint
    addAkt: build.mutation<IAkt, IAkt>({
      query: (body) => ({
        url: "/finance/akt",
        method: "POST",
        body,
      }),
    }),
    //Edit Akt endpoint
    editAkt: build.mutation<IAkt, IEditAkt>({
      query: (data) => {
        let { id, body } = data;
        return {
          url: "/finance/akt/" + id,
          method: "PATCH",
          body,
        };
      },
    }),
    //Delete Akt endpoint
    deleteAkt: build.mutation<IDeleteRes, number>({
      query: (id) => ({
        url: "/finance/akt/" + id,
        method: "DELETE",
      }),
    }),

    ////////// Debt Credit remaining //////////

    //Get DebtCredit endpoint
    getDebtCredit: build.mutation<IDebtCredit & IDebtCreditObj, string>({
      query: (params) => ({ url: `/finance/debtor-creditor${params}` }),
    }),
    getDebtCreditBalances: build.mutation<IDebtCredit, string>({
      query: (params) => ({
        url: `/finance/debtor-creditor/balances${params}`,
      }),
    }),
    //Add DebtCredit endpoint
    addDebtCredit: build.mutation<IDebtCredit, IRemaining>({
      query: (body) => ({
        url: "/finance/debtor-creditor",
        method: "POST",
        body,
      }),
    }),
    //Edit DebtCredit endpoint
    editDebtCredit: build.mutation<IDebtCredit, IBaseEdit<IRemaining>>({
      query: (data) => {
        let { id, body } = data;
        return {
          url: "/finance/debtor-creditor/" + id,
          method: "PATCH",
          body,
        };
      },
    }),
    //Delete DebtCredit endpoint
    deleteDebtCredit: build.mutation<IDeleteRes, number>({
      query: (id) => ({
        url: "/finance/debtor-creditor/" + id,
        method: "DELETE",
      }),
    }),

    ////////// BasicTools //////////

    //Get BasicTools endpoint
    getBasicTools: build.mutation<IBaseDataRes<IBasicToolsRes>, string>({
      query: (params) => ({ url: `/finance/amortization?${params}` }),
    }),
    //Add BasicTools endpoint
    addBasicTools: build.mutation<IBasicToolsRes, IBasicTools>({
      query: (body) => ({
        url: "/finance/amortization",
        method: "POST",
        body,
      }),
    }),
    //Edit BasicTools endpoint
    editBasicTools: build.mutation<IBasicToolsRes, IBaseEdit<IBasicTools>>({
      query: (data) => {
        let { id, body } = data;
        return {
          url: "/finance/amortization/" + id,
          method: "PATCH",
          body,
        };
      },
    }),
    //Delete BasicTools endpoint
    deleteBasicTools: build.mutation<IDeleteRes, number>({
      query: (id) => ({
        url: "/finance/amortization/" + id,
        method: "DELETE",
      }),
    }),

    ////////// Purchase //////////

    //Get Purchase endpoint
    getPurchase: build.mutation<IBaseDataRes<IPurchaseRes>, string>({
      query: (params) => ({ url: `/finance/sale?${params}` }),
    }),
    //Add Purchase endpoint
    addPurchase: build.mutation<IPurchaseRes, IPurchase>({
      query: (body) => ({
        url: "/finance/sale",
        method: "POST",
        body,
      }),
    }),
    //Edit Purchase endpoint
    editPurchase: build.mutation<IPurchaseRes, IBaseEdit<IPurchase>>({
      query: (data) => {
        let { id, body } = data;
        return {
          url: "/finance/sale/" + id,
          method: "PATCH",
          body,
        };
      },
    }),
    //Delete Purchase endpoint
    deletePurchase: build.mutation<IDeleteRes, number>({
      query: (id) => ({
        url: "/finance/sale/" + id,
        method: "DELETE",
      }),
    }),

    //////// Budget pnl ////////
    //Get budget pnl endpoint
    getBudgetPnlDiagram: build.mutation<IPnlRes, string>({
      query: (params) => ({ url: "/finance/diagrams/bpnl-diagram?" + params }),
    }),

    //Get budget pnl endpoint
    getBudgetPnl: build.mutation<IBaseDataRes<IBudgetPnlRes>, void>({
      query: () => ({ url: "/finance/budget-profit-loss" }),
    }),

    //Add budget pnl endpoint
    addBudgetPnl: build.mutation<IBudgetPnlRes, IBudgetPnl>({
      query: (body) => ({
        url: "/finance/budget-profit-loss",
        method: "POST",
        body,
      }),
    }),

    //Edit budget pnl endpoint
    editBudgetPnl: build.mutation<IBudgetPnlRes, IBaseEdit<IBudgetPnl>>({
      query: ({ id, body }) => ({
        url: "/finance/budget-profit-loss/" + id,
        method: "PUT",
        body,
      }),
    }),

    //Delete budget pnl endpoint
    deleteBudgetPnl: build.mutation<IBudgetPnlRes, number>({
      query: (id) => ({
        url: "/finance/budget-profit-loss/" + id,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  /////// Dashboard ///////
  useGetAccountBalancesStatMutation,
  useGetCashflowDashboardMutation,
  /////// Stat ///////
  useGetPnlMutation,
  useGetPnlStatMutation,
  useGetPnlProfitSoftStatMutation,
  useGetCashBalanceStatMutation,
  useGetIncomeOutcomeStatMutation,
  useGetAccountsStatMutation,
  useGetSourcesStatMutation,
  useGetDirectionsStatMutation,
  useGetSourcesOutcomeStatMutation,
  /////// Cashflow ///////
  useGetCashflowMutation,
  useGenerateCashflowMutation,
  useGetCashflowPredictedMutation,
  /////// Categories ///////
  useGetCategoriesQuery,
  /////// Account ///////
  useGetAccountAllQuery,
  useGetAccountMutation,
  useAddAccountMutation,
  useEditAccountMutation,
  useDeleteAccountMutation,
  /////// Source ///////
  useGetSourceAllQuery,
  useGetSourceMutation,
  useGetSourceParentQuery,
  useAddSourceMutation,
  useEditSourceMutation,
  useDeleteSourceMutation,
  /////// Journal ///////
  useGetJournalMutation,
  useGenerateJournalMutation,
  useAddJournalMutation,
  useEditJournalMutation,
  useConfirmJournalMutation,
  useDeleteJournalMutation,
  useGetJournalChangeMutation,
  useConfirmJournalChangeMutation,
  useDeleteJournalChangeMutation,
  useGetJournalPredictedMutation,
  useGenerateJournalPredictedMutation,
  useAddJournalPredictedMutation,
  useEditJournalPredictedMutation,
  useDeleteJournalPredictedMutation,
  /////// Journal payment calendar ///////
  useGetJournalPaymentCalendarMutation,
  useAddJournalPaymentCalendarMutation,
  useDeleteJournalPaymentCalendarMutation,
  useEditJournalPaymentCalendarMutation,
  useGetPCbetweenMutation,
  /////// Monitor ///////
  useGetMonitorMutation,
  useAddMonitorMutation,
  useEditMonitorMutation,
  useDeleteMonitorMutation,
  /////// Transactions ///////
  useGetTransactionsMutation,
  useAddTransactionMutation,
  /////// Direction ///////
  useGetDirectionMutation,
  useAddDirectionMutation,
  useEditDirectionMutation,
  useDeleteDirectionMutation,
  useGetDirectionAllQuery,
  /////// Currency ///////
  useGetCurrencyMutation,
  useAddCurrencyMutation,
  useEditCurrencyMutation,
  useDeleteCurrencyMutation,
  /////// Salary ///////
  useGetSalaryMutation,
  useAddSalaryMutation,
  useEditSalaryMutation,
  useDeleteSalaryMutation,
  /////// Commitment ///////
  useGetCommitmentMutation,
  useAddCommitmentMutation,
  useEditCommitmentMutation,
  useDeleteCommitmentMutation,
  /////// Akt ///////
  useGetAktMutation,
  useAddAktMutation,
  useEditAktMutation,
  useDeleteAktMutation,
  /////// Akt ///////
  useGetDebtCreditMutation,
  useGetDebtCreditBalancesMutation,
  useAddDebtCreditMutation,
  useEditDebtCreditMutation,
  useDeleteDebtCreditMutation,
  /////// BasicTools ///////
  useGetBasicToolsMutation,
  useAddBasicToolsMutation,
  useEditBasicToolsMutation,
  useDeleteBasicToolsMutation,
  /////// Purchase ///////
  useGetPurchaseMutation,
  useAddPurchaseMutation,
  useEditPurchaseMutation,
  useDeletePurchaseMutation,
  /////// Budget pnl ///////
  useGetBudgetPnlDiagramMutation,
  useGetBudgetPnlMutation,
  useAddBudgetPnlMutation,
  useEditBudgetPnlMutation,
  useDeleteBudgetPnlMutation,
} = financeApi;

export const languageUzKr = {
  home: {
    hero: {
      title1: "Ҳисоб-китобларсиз молия",
      title2: "= йўқотилаётган пуллар",
      description:
        "Бугундан пулларингизни тўғри бошқармасангиз, эртага пул етишмовчилиги сизни бошқаради",
      button1: "Тизимга кириш",
      button2: "Контактингизни қолдиринг",
    },
    explain: {
      title1: "Молиявий ҳисоб-китоб",
      title2: "мураккаб жараён",
      card1Title: "Динамик дашбоардлар билан стратегик режалаштириш",
      card1Text:
        "Батафсил молиявий режаларни тузинг ва компаниянгизнинг молиявий кўрсаткичларини ҳақиқий вақтда кузатинг, бу эса қарор қилиш ва давомли муваффақият учун режа тузишни осонлаштиради",
      card2Title: "Соф фойдани аниқ ҳисобланг",
      card2Text:
        "Молиявий маълумотларингизни тўлиқ бошқариб, соф фодани аниқ ҳисобланг. Таҳлилларингизга моддий ва номоддий даромад оқимларини қўшиб, молиявий барқарорлигингизни янада аниқроқ қилинг, стратегик режалаштиринг.",
      card3Title: "Ролга мослаштирилган кириш",
      card3Text:
        "Ҳар бир ходимнинг ролига мувофиқ кўриш ва функциялиликни мослаштиринг, бу жамоа аъзоларига уларнинг масъулиятларига тегишли маълумотлар ва вазифаларга эътибор қаратишга имкон беради",
      card4Title: "Сунъий идрок сизни бизнесингизни тушинади",
      card4Text:
        "Сунъий идрок кучидан фойдаланиб, компаниянгизнинг молиявий ҳолатига мослаштирилган шахсий молиявий маслаҳат олинг. Бизнинг ақлли тизим молиявий ҳолатингизни тушунади, молиявий стратегиянгизни оптималлаштириш учун тушунчалар ва тавсиялар таклиф этади.",
      card5Title: "Молиявий ҳолатингизни аниқ таҳлил қилинг",
      card5Text:
        "Бизнесингизни молиявий ҳолатини тўлиқ кузатинг. Бизнинг кенг доирадаги жадваллар ва чизиқли графиклар орқали молиявий кўрсаткичларингизни чуқур таҳлил қилинг, батафсил таҳлиллар билан қарор қабул қилиш жараёнини яхшиланг",
      card6Title: "Молиявий кирим-чиқимни тасдиқлаш",
      card6Text:
        "Ақчам Телеграм Бот орқали жамоа ишлатган харажатлар ва даромадлар бўйича билдиришномалар қабул қилади, бу сизнинг жамоангиз бўйлаб ҳисобдорлик ва шаффофликни оширади.",
    },
    about: {
      title1: "Дастурнинг тўғридан-тўғри",
      title2: "устунликлари",
      card1Title: "Чат GPT 4 билан узвий боғланган",
      card2Title: "Автоматик таҳлиллар",
      card3Title: "Молия бўйича маслаҳатлар",
      card4Title: "Молия бўйича бепул дарсликлар",
      card5Title: "Қулай бошқарув панели",
      card6Title: "Дастурни ишлатиш бўйича видеоқўлланмалар",
    },
    priority: {
      title1: "Ақчам - сунъий интеллект",
      title2: "ёрдамида ишлайдиган молия модели",
      description:
        "Бу ерда хатоликлар ва камчиликлар бўлмайди. Оддий ва зерикарли шаблонлар билан молия имкониятлари чекланмайди",
      card1line1: "Барча маълумотлар бир жойда тўпланади",
      card1line2: "Автоматик ҳисоб-китоблар",
      card1line3:
        "Автоматик равишда яратилган график ва бошқарув панелидаги тайёр визуал ҳисоботлар",
      card1line4: "Сунъий интеллект ёрдамида оптималлаштириш",
      card1line5:
        "Ўчиб кетган операцияларни қайта тиклаш мумкин. Барча маълумотлар серверда сақланади",
      card1line6:
        "Нақд пул бўшлиқларининг олдини олиш ва олдиндан ҳар қандай давр учун молиявий прогнозларни яратиш",
      card2line1: "Интерфейснинг мураккаблиги.",
      card2line2: "Баъзи ҳисоб-китобларнинг секинлиги.",
      card2line3: "Ижодий эркинлик йўқ.",
      card2line4: "Маълумотларни киритиш ва олишдаги мураккаблик",
      card2line5: "Ҳисоб-китоблар тезлиги паст",
      card2line6: "Шунга кўра, ҳужжатлар ва ҳисоботлар сони ортади.",
      card3line1: "Жуда кўп жадваллар",
      card3line2: "Нотўғри формулалар туфайли ҳисоб-китоблардаги хатолар",
      card3line3:
        "Маълумотларни таҳлил қилишнинг мураккаблиги ва чекланган ҳисобот имкониятлари",
      card3line4: "Маълумотларни қўлда киритиш",
      card3line5: "Хавфсизлик даражаси паст",
      card3line6: "Экcелда пул оқими режасини тузиб бўлмайди",
    },
    team: {
      title: "Бизнинг жамоа",
      card1Title: "ЙИЛЛИК ТАЖРИБА",
      card2Title: "МОЛИЯ МОДЕЛЛАРИ",
      card3Title: "МОЛИЯЧИ ДИРЕКТОРЛАР",
      card4Title: "ЛОЙИҲА",
    },
    partners: {
      title1: "Бизга ишонган",
      title2: "ҳамкорларимиз",
    },
    contact: {
      title1: "Исталган вақтда",
      title2: "биз билан боғланинг",
      formTitle: "Рақамингизни қолдиринг, биз сиз билан боғланамиз",
      button: "Юбориш",
    },
    footer: {
      description:
        "Молияни бошқариш учун онлайн дастур. Бу дастур орқали пулни, даромадни, фойдани бошқарасиз!",
      linkTitle: "Ҳаволалар",
      blogTitle: "Блог",
      blogLine1: "Фойдаланиш шартлари",
      blogLine2: "Махфийлик сиёсати",
      adressTitle: "БИЗНИНГ МАНЗИЛ",
      adressLine1:
        "Тошкент шахри, Шота Руставели кўчаси, 1-уй. МФактор биносида",
      contactButton: "Бизга алоқага чиқинг",
    },
    menu: {
      menu1: "Дастур ҳақида",
      menu2: "Имкониятлар",
      menu3: "Ҳамкорлар",
      menu4: "Нархлар",
      menu5: "Боғланиш",
    },
  },
  auth: {
    signUp: {
      title1: "Ақчамга ",
      title2: "хуш келибсиз",
      description:
        "Бизнесда аниқ рақамларни билмасангиз, фойда ва зарар, кирим ва чиқим ҳисоботлари билан таниш бўлмасангиз, аниқ ютқазасиз",
      formTitle: "АҚЧАМ га хуш келибсиз",
      firstNameLabel: "Исим",
      firstNamePlaceholder: "Исмингиз",
      lastNameLabel: "Фамиля",
      lastNamePlaceholder: "Фамилянгиз",
      emailLabel: "Эмаил",
      emailPlaceholder: "Эмаил почтангиз",
      passwordLabel: "Парол",
      passwordPlaceholder: "Паролингиз",
      confirmPasswordLabel: "Паролни тасдиқлаш",
      confirmPasswordPlaceholder: "Паролни тасдиқлаш",
      bottomText: "Агар сизда “АҚЧАМ“ ҳисоби бўлса?",
      button: "Рўйхатдан ўтиш",
      signInLink: "Тизимга кириш",
    },
    signIn: {
      formTitle: "Хуш келибсиз!",
      emailLabel: "Эмаил",
      emailPlaceholder: "Эмаил почтангиз",
      passwordLabel: "Парол",
      passwordPlaceholder: "Паролни киритинг",
      forgetPassword: "Паролни унутдингизми?",
      button: "Кириш",
      bottomText: "Агар сизда “АҚЧАМ“ ҳисоби йўқ бўлса?",
      signInLink: "Рўйхатдан ўтиш",
      commentLink: "Сўров қолдириш",
    },
    comment: {
      businessLabel: "Бизнесингиз ҳақида",
      send: "Юбориш",
      phone: "Телефон рақам",
    },
  },
  sidebar: {
    statistics: {
      title: "Контрол панел",
      menu1: "Cashflow",
      menu2: "Фойда ва зарар",
    },
    check: {
      title: "Тасдиқлаш",
      menu1: "Таҳрирланган",
    },
    cash: {
      title: "Касса",
      menu1: "Касса журнали",
      menu2: "Касса чиқариш",
      menu3: "Ўтказма",
    },
    cashflow: {
      title: "Пул оқими",
      selectTitle: "Пул оқимини танланг",
    },
    pnl: {
      title: "Фойда ва Зарар",
      menu1: "Фойда ва Зарар",
      menu2: "Мажбурият",
      menu3: "АКТ",
      f_calendar: "Молиявий календар",
      statistcCashflow: "Cash Flow статистика маълумотлари",
      statistcPnl: "Фойда ва зарар статистика маълумотлари",
      debitor: "Дебитор & Кредитор",
    },
    planned: {
      title: "Режалаштириш",
      menu1: "Режа кирим & чиқим",
      menu2: "Бюджет пул оқими",
    },
    info: {
      title: "Маълумотлар",
      menu1: "Валюталар",
      menu2: "Манбалар",
      menu3: "Ҳисоб рақамлар",
      menu4: "Йўналишлар",
      menu5: "Ходимлар",
      menu6: "Контрагентлар",
    },
    kompany: {
      title: "Компания",
    },
    branchs: {
      title: "Филиаллар",
    },
  },
  common: {
    all: "Жами",
    uzs: "сўм",
    rubl: "рубл",
    name: "Номи",
    view: "Кўриш",
    enterName: "Номини киритинг",
    dollar: "доллар",
    income: "Кирим",
    outcome: "Чиқим",
    currency: "Валюта",
    currencyRate: "Валюта курси",
    currencyRateForDollar: "Валюта курси ($1 учун)*",
    transfer: "Ўтказма",
    today: "Бугун",
    thisMonth: "Бу ой",
    lastMonth: "Ўтган ой",
    difference: "Фарқ",
    date: "Сана",
    intervalDate: "Оралиқ сана",
    actions: "Амаллар",
    plan: "Режа",
    home: "Бош саҳифа",
    dashboard: "Дашбоард",
    fact: "Факт",
    percent: "Фоиз",
    profit: "Даромад",
    comment: "Изоҳ",
    enterComment: "Изоҳни киритинг",
    total: "Умумий",
    barchasi: "Барчаси",
    position: `Лавозим`,
    directions: "Йўналишлар",
    direction: "Йўналиш",
    search: "Қидириш",
    clear: "Тозалаш",
    currentMoneyFlow: "Жорий пул оқими",
    financialMoneyFlow: "Молиявий пул оқими ўзгариши",
    invistationMoneyFlow: "Инвестицион пул оқими ўзгариши",
    naklodnoyOutcome: "Накладной харажат",
    administrativeOutcome: "Административ харажат",
    commersialOutcome: "Тижорий харажат",
    expense: "Харажат",
    expenseType: "Харажат тури",
    subSources: "Суб манбалар",
    enterSubSources: "Суб манбалар",
    taxes: "Солиқлар",
    users: "Ходимлар",
    user: "Ходим",
    addUuser: `Ходим қўшиш`,
    month: "Ой",
    monthly: "Ойлик",
    whichMonth: "Қайси ой",
    whichMonthInfo: "Ходим қайси ой учун иш хақи олди?",
    value: "Таннарх",
    enterValue: "Таннархни киритинг",
    netProfit: "Соф фойда",
    grosProfit: "Ялпи фойда",
    operativProfit: "Оператив фойда",
    morjanalProfit: "Маржанал фойда",
    totalBalance: "Умумий қолдиқ",
    paymentDifference: "Тўлов фарқи",
    accounts: "Аккаунтлар",
    sources: "Манбалар",
    source: "манба",
    Usource: "Манба",
    sourceType: "Манба тури",
    addsource: "Манба қўшиш",
    entersource: "Манба номини киритинг",
    editsource: "манабасини таҳрирлаш",
    selectAccount: "Ҳисоб рақамни танланг",
    account: "Ҳисоб рақам",
    contingents: "Контрагентлар",
    contingent: "Контрагент",
    addcontingent: `Янги контрагент қўшиш`,
    typeContingent: "Контрагент тури",
    selectContingent: "Контрагентни танланг",
    addContingent: "Контрагент қўшиш",
    nameContingent: "Контрагентни номи",
    enterContingent: "Контрагентни номини киритинг",
    selectYear: "Йилни танланг",
    selectDate: "Санани танланг",
    selectMonth: "Ойни танланг",
    selectSource: "Манбани танланг",
    selectCurrency: "Валютани танланг",
    enterCurrencyRate: "Валюта курсини киритинг",
    selectUser: "Ходимни танланг",
    more: "Батафсил",
    filter: "Филтер",
    check: "Текшириш",
    save: "Сақлаш",
    cancel: "Бекор қилиш",
    downloadExcel: "Эксел юклаш",
    amount: "Миқдор",
    enterAmount: "Миқдорни киритинг",
    add: "Қўшиш",
    _add: "қўшиш",
    enterPrice: "Нархни критинг",
    remain: "Қолдиқ",
    entitlement: "ҳақдорлик",
    deposit: "омонат",
    logout: "Тизимдан чиқиш",
    doTransfer: "Ўтказиш",
    editInfo: ` маълумотларини таҳрилаш`,
    priceSum: "Нарх (Сўм)",
    successMessage: "Муваффақиятли қўшилди",
    allBranchsStat: "Барча статистикалар",
  },
  months: {
    jan: "Январ",
    feb: "Феврал",
    march: "Март",
    apr: "Апрел",
    may: "Май",
    jun: "Июн",
    jul: "Июл",
    aug: "Август",
    sep: "Сентябр",
    oct: "Октябр",
    nov: "Ноябр",
    dec: "Декабр",
  },
  dashboard: {
    title: "Бошқарув панел",
    incomeFact: "Тушум режа & факт",
    outcomeFact: "Чиқим режа & факт",
    restFact: "Умумий ўзгариш",
    daromadFact: "Даромад режа & факт",
    xarajatFact: "Харажат режа & факт",
    foydaFact: "Фойда режа & факт",
    paymentYear: "Тўлов календари йил",
    paymentCalendar: "Тўлов календари",
  },
  subControlPanel: {
    title: "Бошқарув ҳисоботлар",
    balance: "Пул қолдиғи",
    expenses: "Манба улушлари",
    income: "Кирим ва чиқим",
    revenue: "Пул оқими улушлари",
    profit_soft: "Даромад ва Соф фойда",
    overhead: "Маржиналлик",
    sourceMontly: "Манба/Ойлар",
  },
  subCheck: {
    uncorfirmedAcc: "Тасдиқланмаган ҳисоблар",
    uncorfirmed: "Тасдиқланмаган",
    unAnswered: "Жавоб берилмаган",
  },
  subCash: {
    historyCash: "Касса тарихи",
    historyTransfer: "Ўтказмалар тарихи",
    exitCash: "Касса чиқди",
    from: "Аккаунтдан",
    to: "Аккаунтга",
    size: "Ўтказилган миқдор",
    transferAmount: "Ўтказма суммаси",
    enterTransferAmount: "Ўтказма суммаси",
    transferDate: "Ўтказилган сана",
    toTransferDate: "Ўтказиш санаси",
    selectToAcc: "Киривчи аккаунтни танланг",
    selectFromAcc: "Чиқувчи аккаунтни танланг",
    hisoboti: "ҳисоботи",
    exactAccount: "Мос ҳисоб рақам",
    exactAccountBalance: "Мос ҳисоб рақамдаги қолдиқ ",
    enterExactAccountBalance: "Мос ҳисоб рақамдаги қолдиқни киритинг !",
    system: "Система",
    difesit: "Камомат",
    bankBalance: "Банкдаги қолдиқ",
    systemBalance: "Системадаги қолдиқ",
    startMonthBalance: "Ой бошига қолдиқ",
    currentBalance: "Жорий пул оқими ўзгариши",
    financialBalance: "Молиявий пул оқими қолдиғи",
    invsetialBalance: "Инвистицион пул оқими қолдиғи",
    changeAccount: "Ҳисоб рақам алмашуви",
    total_change: "Умумий ўзгарма",
    remains_last: "Ой охирига қолдиқ",
    profit: "Даромад",
    enterProfit: "Даромадни киритинг",
    profitPercent: "Даромаддаги %",
    marginProfit: "Маржанал даромад",
    marginPercent: "Маржаналлик %",
    overheadOutcome: "Накладной харажатлар",
    grossAll: "Ялпи фойда (+)/Зарар(-)",
    grossRentabl: "Ялпи фойда рентабеллиги %",
    operativeAll: "Оператив фойда (+)/Зарар(-)",
    operativRentabl: "Оператив фойда рентабеллиги %",
    deprecation: "Амортизация АБ",
    sof: "Соф фойда (+)/Зарар(-)",
    sofRentabl: "Соф фойда рентабеллиги %",
    remansTitle: "Ойни охирига сўмда",
    mainTools: "Асосий воситалар",
    partProfit: "Тақсимланмаган фойда",
    warehause: "Омбор",
    material: "Хом ашё",
    halfProduct: "Ярим тайёр маҳсулот",
    product: "Тайёр маҳсулот",
    debit: "Дебиторка",
    credit: "Кредиторка",
    duties: "Мажбуриятлар",
    suplier: "Мол етказиб берувчилардан",
    _suplier: "Мол етказиб берувчи",
    customers: "Харидорлардан",
    customer: "Харидор",
    selectCustomer: "Харидорни танланг",
    emplyee: "Ходимлардан",
    rent: "Солиқдан",
    moneys: "Пуллар",
    balans: "Баланс",
    personalCapital: "Шахсий капитал",
    active: "Актив",
    passive: "Пассив",
    workingCapital: "Айланма маблағлар",
    budgetTitle: "Бюджет пул оқими",
    budgetPnlTitle: "Бюджет фойда зарар",
    budget_pnl_list: "Режа даромад & харажат",
    budget_pnl_by_month: "Бюджет фойда & зарар",
    selectAccount: "Илтимос аккаунтлардан бирини танланг!",
  },
  subHelper: {
    storage: "Омбор",
    storageAddTitle: "Омбор маълумотларини қўшиш",
    storageType: "Махсулот тури",
    selectStorageType: "Махсулот турини танланг",
    storageRowMaterials: "Хом ашё",
    storageSemiFinished: "Ярим тайёр махсулот",
    storageFinished: "Тайёр махсулот",
    salary: "Иш ҳақи",
    debitCredit: "Дебит-кредит",
    purchase: "Харидлар",
    mainToll: "Асосий воситалар",
    title: "Ёрдамчи ҳисоботлар",
    editTitle: "Иш ҳақини таҳрирлаш",
    addTitle: "Иш ҳақини қўшиш",
    editDutyTitle: "Мажбуриятни таҳрирлаш",
    addDutyTitle: "Мажбуриятни қўшиш",
    aktNumber: "Акт рақами",
    enterAktNumber: "Акт рақамини киритинг",
    aktDate: "Акт санаси",
    serviceType: "Хизмат тури",
    editAktTitle: "Мажбуриятни таҳрирлаш",
    addAktTitle: "Мажбуриятни қўшиш",
    editPurchaseTitle: "Харидни таҳрирлаш",
    addPurchaseTitle: "Харидни қўшиш",
    purchaseDate: "Харид қилиш санаси",
    attritionMonthly: "Эскириш (ойлар кесимида)",
    monthly_rate: "Бир ойлик амортизация",
    total_months: "Неча ой фойдаланилади",
    current_balance: "Қолдиқ суммаси (Сўм)",
    initial_balance: "Бошланғич нархи (Сўм)",
    editMainTitle: "Асосий воситани таҳрирлаш",
    addMainTitle: "Асосий воситани қўшиш",
    editInformation: "маълумотларини таҳрирлаш",
    bookWithCustomers: "Харидорлар билан ҳисоб китоблар",
    madeService: "Хизмат кўрсатилди",
    payedCustomer: "Харидор тўлади",
    bookWithDillers: "Етказиб берувчилар билан ҳисоб-китоблар",
    buyed: "Харид қилинди",
    payed: "Тўланди",
    bookWithEmployes: "Ходимлар билан ҳисоб-китоблар",
    calculated: "Ҳисобланди",
  },
  subInfo: {
    initial_money: "Бошланғич қолдиқ",
    start_date: `Бошланғич сана`,
    enterStartDate: `Бошланғич санани киритинг`,
    enterInitial_money: "Бошланғич қолдиқни киритинг",
    addAccount: "Ҳисоб рақам қўшиш",
    editAccount: "ҳисоб рақамини таҳрирлаш",
    nameAccount: "Ҳисоб рақам номи",
    enterAccount: "Ҳисоб рақам номини киритинг",
    balanceAccount: "Ҳисоб бошлангич рақам баланси",
    enterBalanceAccount: "Хисоб ракам бошлангич балансини киритинг",
    addDirection: "Йўналиш қўшиш",
    editDirection: "Йўналиш маълумотларини таҳрирлаш",
    nameDirection: "Йўналиш номи",
    enterDirection: "Йўналиш номини киритинг",
    phoneNumber: "Телефон рақам",
    enterCtrPhone: "Контрагент телефон рақамини киритинг",
  },
  company: {
    alert: "Илтимос тизимдан фойдаланиш учун аввал ўз компаниянгизни киритинг",
    addCompany: "Компания қўшиш",
    nameCompany: "Компания номи",
    enterCompany: "Компания номини киритинг",
  },
  branches: {
    addBranch: `Филиал қўшиш`,
    nameBranch: `Филиал номи`,
    enterBranch: `Филиал номини киритинг`,
  },
};
